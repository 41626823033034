import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./Tickets.css";

const TicketChat = () => {
  const { ticketId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const chatEndRef = useRef(null); // Ref for scrolling to the last message
  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem("profile"))
  );
  const [ticketInfo, setTicketInfo] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTicketData = async () => {
      try {
        const ticketRes = await axios.get(
          `https://fttunes.com/api/support/tickets/artist/${profile.artistId}`
        );
        console.log("ticket id", ticketId);
        console.log("ticket info api response", ticketRes.data.tickets);
        const reqTicket = ticketRes.data.tickets.filter(
          (ticket) => ticket.ticket_id === ticketId
        )[0];
        console.log("req ticket", reqTicket);
        setTicketInfo(reqTicket);

        const messagesRes = await axios.get(
          `https://fttunes.com/api/support/tickets/${ticketId}/messages`
        );
        setMessages(messagesRes.data.messages);
      } catch (error) {
        console.error("Error fetching ticket data", error);
      }
    };

    fetchTicketData();
  }, [ticketId]);

  const sendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      await axios.post("https://fttunes.com/api/support/tickets/messages", {
        ticketId,
        senderId: profile.artistId,
        receiverId: "admin",
        message: newMessage,
      });
      setNewMessage("");
      setMessages([
        ...messages,
        { sender_id: profile.artistId, message: newMessage },
      ]);
    } catch (error) {
      console.error("Error sending message", error);
    }
  };

  // Scroll to the last message whenever messages update
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  console.log("ticket info", ticketInfo);
  console.log("messages", messages);

  return (
    <div className="ticket-chat-container ticket-bg">
      {/* Left Panel - Ticket Info */}
      <div className="ticket-info-panel bg-secondary">
        <h3>Ticket Information</h3>
        <p>
          <strong>Ticket Id:</strong> {ticketInfo.ticket_id}
        </p>
        <p>
          <strong>Subject:</strong> {ticketInfo.subject}
        </p>
        <p>
          <strong>Ticket Raised:</strong> {ticketInfo.created_at}
        </p>
        <p>
          <strong>Status:</strong>{" "}
          <span
            className={`status-${
              ticketInfo.status && ticketInfo.status.toLowerCase()
            }`}
          >
            {ticketInfo.status}
          </span>
        </p>
        <p>
          <strong>Last Reply:</strong>{" "}
          {messages &&
            messages[messages.length - 2] &&
            messages[messages.length - 2].created_at}
        </p>
        {/* <button className="reply-btn">Reply</button> */}
        <button className="close-btn" onClick={() => navigate(-1)}>
          Close
        </button>
      </div>

      {/* Right Panel - Chat Section */}
      <div className="chat-section">
        <h2>Ticket Chat - {ticketId}</h2>
        <div className="chat-messages bg-secondary">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`message ${
                msg.sender_id === "admin" ? "received" : "sent"
              }`}
            >
              <p>{msg.message}</p>
            </div>
          ))}
          {/* Dummy div to keep the latest message in view */}
          <div ref={chatEndRef} />
        </div>

        {/* Message Input Box */}
        <div className="chat-input">
          <textarea
            placeholder="Type your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            rows={4}
            className="bg-secondary"
          />
          <div className="chat-actions">
            {/* <button className="attach-btn">📎 Attach Files</button> */}
            <button className="send-btn" onClick={sendMessage}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketChat;
