import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import "./AddArtist.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";

const AddArtist = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(
      JSON.parse(localStorage.getItem("profile"))
    );
//   useEffect(() => {
//     const storedProfile = JSON.parse(localStorage.getItem("profile"));
//     setProfile(storedProfile);
//     console.log("profile", storedProfile);

//     const fetchprofile = async () => {
//       try {
//         const response = await axios.get(
//           `https://fttunes.com/api/artists/${storedProfile.artistId}`
//         );
//         localStorage.setItem("profile", JSON.stringify(response.data));
//         setProfile(response.data); // Update state correctly
//       } catch (error) {
//         console.error("Error fetching artist data:", error);
//       }
//     };

//     fetchprofile();
//   }, [updateprofile]);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    doj: "",
    gender: "",
    profileImage: "/Images/Artists/profile.jpg",
    description: "",
    websiteUrl: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    facebookUrl: "",
    twitterUrl: "",
    instagramUrl: "",
    linkedInUrl: "",
    spotifyUrl: "",
    wynkUrl: "",
    gaanaUrl: "",
    bankAccountName: "",
    bankAccountNumber: "",
    bankName: "",
    ifscCode: "",
    swiftCode: "",
    labelId: profile.artistId,
  });

  const handleInputChange = (e) => {
    // console.log("e", e.target);
    const { name, value, type, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Create a FormData object to handle file uploads
    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] instanceof File) {
        data.append(key, formData[key]);
      } else {
        data.append(key, formData[key]);
      }
    });

    console.log("formdata at api call", formData);

    try {
      // Make the API call
      const response = await axios.post(
        "https://fttunes.com/api/artists/under-label",
        data, // Pass FormData object here
        { headers: { "Content-Type": "multipart/form-data" } } // Important for file uploads
      );
      console.log("Artist added successfully:", response.data);
      navigate("/artist/dashboard");
      // Handle success (e.g., redirect or show a success message)
    } catch (error) {
      console.error("Error adding artist:", error.response.data);
      // Handle error (e.g., show an error message)
    }
  };

  const [step, setStep] = useState(1); // State to keep track of the current step

  // Function to handle next button click
  const handleNext = () => {
    if (step < 4) {
      setStep(step + 1);
    }
  };

  // Function to handle previous button click
  const handlePrev = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  // Render the form steps based on the current step
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <StepOne formData={formData} handleInputChange={handleInputChange} />
        );
      case 2:
        return (
          <StepTwo formData={formData} handleInputChange={handleInputChange} />
        );
      case 3:
        return (
          <StepThree
            formData={formData}
            handleInputChange={handleInputChange}
          />
        );
      case 4:
        return (
          <StepFour formData={formData} handleInputChange={handleInputChange} />
        );
      default:
        return null;
    }
  };

  return (
    <Container className="add-artist" style={{ padding: "2rem" }}>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Button
                variant="primary"
                className="theme-btn add_artist_btn"
                // style={{margin:"20px 10px"}}
                onClick={() => {
                  navigate(-1);
                }}
              >
               <IoMdArrowRoundBack/> Back
              </Button>
              <h4 className="card-title">
                <mark>Add Artist</mark>
              </h4>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <div
                  id="smartwizard"
                  className="form-wizard order-create sw sw-theme-default sw-justified"
                >
                  <ul className="nav nav-wizard">
                    {[
                      "Personal Info",
                      "Contact Info",
                      "Social Links",
                      "Bank Account Details",
                    ].map((label, index) => (
                      <li key={index}>
                        <Button
                          className={`nav-link ${
                            step >= index + 1 ? "active" : "inactive"
                          }`}
                          onClick={() => setStep(index + 1)}
                        >
                          <span>{index + 1}</span>
                        </Button>
                      </li>
                    ))}
                  </ul>
                  <div className="tab-content">{renderStep()}</div>
                  <div
                    className="toolbar toolbar-bottom"
                    role="toolbar"
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      className="btn btn-primary sw-btn-prev"
                      onClick={handlePrev}
                      disabled={step === 1}
                    >
                      Previous
                    </Button>
                    <Button
                      className="btn btn-primary sw-btn-next"
                      onClick={handleNext}
                      disabled={step === 4}
                    >
                      Next
                    </Button>
                    {step === 4 && (
                      <Button
                        type="submit"
                        className="btn btn-primary sw-btn-submit"
                      >
                        Add Artist
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const StepOne = ({ formData, handleInputChange }) => (
  <div id="wizard_PersonalInfo" className="tab-pane">
    <Row>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            First Name<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="firstName"
            placeholder="First Name"
            required
            value={formData.firstName}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Last Name<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="lastName"
            placeholder="Last Name"
            required
            value={formData.lastName}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
      {/* <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Date of Joining<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="date"
            name="doj"
            required
            value={formData.doj}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col> */}
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Gender<span className="required">*</span>
          </Form.Label>
          <Form.Control
            as="select"
            name="gender"
            required
            value={formData.gender}
            onChange={handleInputChange}
          >
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </Form.Control>
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Profile Image<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="file"
            name="profileImage"
            required
            // value={formData.profileImage}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
      <Col lg={12} className="mb-2">
        <Form.Group>
          <Form.Label>
            Brief Description<span className="required">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            rows={3}
            required
            value={formData.description}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
      <Col lg={12} className="mb-2">
        <Form.Group>
          <Form.Label>Website URL</Form.Label>
          <Form.Control
            type="url"
            name="websiteUrl"
            placeholder="https://yourwebsite.com"
            value={formData.websiteUrl}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
    </Row>
  </div>
);

const StepTwo = ({ formData, handleInputChange }) => (
  <div id="wizard_ContactInfo" className="tab-pane">
    <Row>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Email Address<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="example@example.com"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Phone Number<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="tel"
            name="phone"
            placeholder="(+1) 408-657-9007"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Password<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Confirm Password<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            value={formData.confirmPassword}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
    </Row>
  </div>
);

const StepThree = ({ formData, handleInputChange }) => (
  <div id="wizard_SocialLinks" className="tab-pane">
    <Row>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>Facebook URL</Form.Label>
          <Form.Control
            type="url"
            name="facebookUrl"
            placeholder="https://facebook.com/yourprofile"
            value={formData.facebookUrl}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>Twitter URL</Form.Label>
          <Form.Control
            type="url"
            name="twitterUrl"
            placeholder="https://twitter.com/yourprofile"
            value={formData.twitterUrl}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>Instagram URL</Form.Label>
          <Form.Control
            type="url"
            name="instagramUrl"
            placeholder="https://instagram.com/yourprofile"
            value={formData.instagramUrl}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>LinkedIn URL</Form.Label>
          <Form.Control
            type="url"
            name="linkedInUrl"
            placeholder="https://instagram.com/yourprofile"
            value={formData.linkedInUrl}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>

      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>Spotify URL</Form.Label>
          <Form.Control
            type="url"
            name="spotifyUrl"
            placeholder="https://spotify.com/yourprofile"
            value={formData.spotifyUrl}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>Wynk URL</Form.Label>
          <Form.Control
            type="url"
            name="wynkUrl"
            placeholder="https://wynk.com/yourprofile"
            value={formData.wynkUrl}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>Gaana URL</Form.Label>
          <Form.Control
            type="url"
            name="gaanaUrl"
            placeholder="https://gaana.com/yourprofile"
            value={formData.gaanaUrl}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Col>
    </Row>
  </div>
);

const StepFour = ({ formData, handleInputChange }) => (
  <div id="wizard_BankAccountDetails" className="tab-pane">
    <Row>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Bank Account Name<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="bankAccountName"
            placeholder="Name as in Bank Account"
            value={formData.bankAccountName}
            onChange={handleInputChange}
            // value={formData.bankAccountName || ""}
            // onChange={handleInputChange}
            // required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Bank Account Number<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="bankAccountNumber"
            placeholder="Account Number"
            value={formData.bankAccountNumber}
            onChange={handleInputChange}
            // value={formData.bankAccountNumber || ""}
            // onChange={handleInputChange}
            // required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Bank Name<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="bankName"
            placeholder="Bank Name"
            value={formData.bankName}
            onChange={handleInputChange}
            // value={formData.bankName || ""}
            // onChange={handleInputChange}
            // required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Bank Routing Number<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="ifscCode"
            placeholder="IFSC Code"
            value={formData.ifscCode}
            onChange={handleInputChange}
            // value={formData.ifscCode || ""}
            // onChange={handleInputChange}
            // required
          />
        </Form.Group>
      </Col>
      <Col lg={6} className="mb-2">
        <Form.Group>
          <Form.Label>
            Swift Code<span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="swiftCode"
            placeholder="Swift Code"
            value={formData.swiftCode}
            onChange={handleInputChange}
            // value={formData.ifscCode || ""}
            // onChange={handleInputChange}
            // required
          />
        </Form.Group>
      </Col>
    </Row>
  </div>
);

export default AddArtist;
