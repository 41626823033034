import React, { useState } from "react";
import axios from "axios";
import "./Tickets.css";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const RaiseTicket = () => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem("profile"))
  );
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://fttunes.com/api/support/tickets", {
        artistId: profile.artistId,
        subject,
        message,
      });
      setSubject("");
      setMessage("");
      alert("Ticket Raised Successfully!");
    } catch (error) {
      console.error("Error raising ticket", error);
    }
  };

  const editProfile = () => {
    navigate("/artist/profile");
  };

  return (
    <div className="ticket-chat-container ticket-bg">
      <div className="ticket-info-panel">
        <Card bg="secondary" text="light" className="mb-4">
          <Card.Header className="text-center">Artist Info</Card.Header>
          <Card.Body>
            <Card.Title>
              {profile.firstName} {profile.lastName}
            </Card.Title>
            <Card.Text>
              {profile.role}
              <br />
              {profile.description}
              <br />
              {profile.address}
              <br />
              {profile.city}
              {profile.city && ", "}
              {profile.state}
              {profile.state && ", "}
              {profile.pincode}
              <br />
              {profile.country}
              <br />
              {profile.plan_purchased}
              <br />
              ID: {profile.artistId}
            </Card.Text>
            <Button
              variant="success"
              className="w-100 mt-3"
              onClick={() => editProfile()}
            >
              Update
            </Button>
          </Card.Body>
        </Card>
        <Card bg="secondary" text="light">
          <Card.Header className="text-center">Contacts</Card.Header>
          <Card.Body>
            <Card.Text>
              Phone : {profile.phone}
              <br />
              Email : {profile.email}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>

      {/* Right Panel - Raise Ticket Form */}
      <div className="chat-section">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "15px 0px",
          }}
        >
          <h2>Raise a New Support Ticket</h2>{" "}
          <Button onClick={() => navigate(-1)}>Close</Button>
        </div>
        <form onSubmit={handleSubmit} className="ticket-form">
          <input
            type="text"
            placeholder="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
          <textarea
            placeholder="Describe your issue..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={10}
            required
          ></textarea>
          <button type="submit" className="send-btn">
            Submit Ticket
          </button>
        </form>
      </div>
    </div>
  );
};

export default RaiseTicket;
