import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Form,
  Button,
  Col,
  Modal,
  Row,
  Dropdown,
  Pagination,
} from "react-bootstrap";
import { FaCircle, FaDownload, FaEye, FaTrash } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const AllReportsTable = () => {
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const artistProfile = JSON.parse(localStorage.getItem("profile"));
        const artistId = artistProfile?.artistId;

        if (!artistId) {
          setError("Artist ID not found");
          return;
        }
        console.log("artist Idddddd", artistId);
        const response = await axios.get(
          `https://fttunes.com/api/reports/${artistId}`
        );
        console.log("reports", response.data.reports);
        setReports(response.data.reports); // Adjust based on your API response structure
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch reports");
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  //   const handleDeleteReport = async (id) => {
  //     if (window.confirm("Are you sure you want to delete this report?")) {
  //       try {
  //         await axios.delete(`https://fttunes.com/api/reports/${id}`); // Adjust API endpoint
  //         setReports(reports.filter((report) => report.id !== id));
  //       } catch (error) {
  //         console.error("Error deleting report:", error);
  //       }
  //     }
  //   };
  const [selectedReport, setSelectedReport] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleViewDetails = (report) => {
    setSelectedReport(report);
    setShowModal(true);
  };

  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // Pagination Logic
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = reports.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(reports.length / entriesPerPage);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Col className="artists-table">
      <Card>
        <Card.Header>
          <h4 className="card-title">
            <mark>My Reports</mark>
          </h4>

          <Dropdown className="float-end">
            <Dropdown.Toggle variant="secondary">
              Show {entriesPerPage} rows
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {[10, 50, 100].map((num) => (
                <Dropdown.Item key={num} onClick={() => setEntriesPerPage(num)}>
                  {num} rows
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {/* <Button
            as={Link}
            to="/artist/reports/create"
            className="theme-btn add_report_btn"
          >
            Add New Report
          </Button> */}
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <Table responsive="md" variant="dark">
              <thead>
                <tr>
                  <th style={{ width: "50px" }}>
                    <Form.Check
                      type="checkbox"
                      id="checkAllReports"
                      className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                      style={{ border: "none" }}
                      label=""
                    />
                  </th>
                  <th>ID</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Remarks</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries.length > 0 ? (
                  currentEntries.map((report, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Check
                          type="checkbox"
                          id={`customCheckBoxReport${index + 2}`}
                          className="form-check-input custom-checkbox checkbox-primary check-lg me-3"
                          label=""
                          style={{ border: "none" }}
                        />
                      </td>
                      <td>
                        <strong>{report.id}</strong>
                      </td>
                      <td>{new Date(report.startTime).toLocaleDateString()}</td>
                      <td>{new Date(report.endTime).toLocaleDateString()}</td>
                      <td>{report.remarks}</td>
                      <td>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() => handleViewDetails(report)}
                        >
                          <FaEye /> View
                        </Button>
                        <Button
                          variant="success"
                          href={`https://fttunes.com/api/${report.filePath}`}
                          target="_blank"
                          size="sm"
                          // onClick={() =>
                          //   navigate(`https://fttunes.com/${report.filePath}`)
                          // }
                          style={{ marginLeft: "5px" }} // Optional: adds space between buttons
                        >
                          <FaDownload /> Download
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No report to show.</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <Pagination variant="dark" className="justify-content-center mt-3">
            <Pagination.Prev
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            />
            <span className="mx-3">
              Showing {indexOfFirstEntry + 1} to{" "}
              {Math.min(indexOfLastEntry, reports.length)} of {reports.length}
            </span>
            <Pagination.Next
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Card.Body>
      </Card>

      {/* Modal to show report details */}
      {selectedReport && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="lg"
          centered
          className="change_status_modal"
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: "#1a1a2e",
              borderBottom: "1px solid #76009f",
            }}
          >
            <Modal.Title style={{ color: "#fff", fontWeight: "bold" }}>
              📄 Report Details - {selectedReport.id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#121212", color: "#fff" }}>
            <Row className="mb-3">
              <Col md={6}>
                <div
                  className="p-3 rounded"
                  style={{ backgroundColor: "#1a1a2e" }}
                >
                  <h5 className="mb-3 text-primary">📆 Report Information</h5>
                  <p>
                    <strong>Start Date:</strong>{" "}
                    {new Date(selectedReport.startTime).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )}
                  </p>
                  <p>
                    <strong>End Date:</strong>{" "}
                    {new Date(selectedReport.endTime).toLocaleDateString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )}
                  </p>
                  <p>
                    <strong>Remarks:</strong> {selectedReport.remarks || "N/A"}
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div
                  className="p-3 rounded"
                  style={{ backgroundColor: "#1a1a2e" }}
                >
                  <h5 className="mb-3 text-primary">📂 Report File</h5>
                  <p>
                    <strong>File Name:</strong> {selectedReport.fileName}
                  </p>
                  <p>
                    <strong>Download:</strong>{" "}
                    <a
                      href={`https://fttunes.com/api/${selectedReport.filePath}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-sm btn-outline-primary"
                    >
                      📥 Download Report
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer
            style={{
              backgroundColor: "#1a1a2e",
              borderTop: "1px solid #76009f",
            }}
          >
            <Button
              variant="outline-light"
              onClick={() => setShowModal(false)}
              style={{ borderColor: "#76009f" }}
            >
              ❌ Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Col>
  );
};

export default AllReportsTable;
