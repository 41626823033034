import React, { useEffect, useState } from "react";
// import { FaCircle, FaEye, FaPencil, FaTrash } from "react-icons/fa6";
import {
  Card,
  Table,
  // Form,
  // Button,
  Col,
  Row,
  Container,
  Dropdown,
  Pagination,
} from "react-bootstrap";
// import { Link } from "react-router-dom";
import { FaGem, FaRupeeSign } from "react-icons/fa";
import axios from "axios";

const Payouts = () => {
  // const payoutHistory = [
  //   {
  //     id: "P001",
  //     remarks: "Echoes of Silence (Album)",
  //     payoutDate: "15 August 2023",
  //     amount: "1,500",
  //     // status: "Completed",
  //     // statusClass: "success",
  //     payoutPeriod: "15 May 2023 - 15 August 2023",
  //   },
  //   {
  //     id: "P002",
  //     remarks: "Celestial Harmony (Single)",
  //     payoutDate: "20 July 2023",
  //     amount: "800",
  //     // status: "Pending",
  //     // statusClass: "warning",
  //     payoutPeriod: "20 April 2023 - 20 July 2023",
  //   },
  //   {
  //     id: "P003",
  //     remarks: "Mystic River (Album)",
  //     payoutDate: "05 June 2023",
  //     amount: "2,000",
  //     payoutPeriod: "05 March 2023 - 05 June 2023",
  //   },
  // ];

  const [payoutHistory, setPayoutHistory] = useState([]);
  const [artistId, setArtistId] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // Get the artistId from localStorage
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (profile && profile.artistId) {
      setArtistId(profile.artistId);
    }

    // Fetch the payout history from the API
    const fetchPayoutHistory = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `https://fttunes.com/api/payouts/artist/${profile.artistId}`
        );
        console.log("payouts", response.data);
        setPayoutHistory(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (profile && profile.artistId) {
      fetchPayoutHistory();
    }
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const totalAmount = payoutHistory.reduce(
    (acc, payout) => acc + payout.amount,
    0
  );

  // Pagination Logic
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = payoutHistory.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const totalPages = Math.ceil(payoutHistory.length / entriesPerPage);

  return (
    <div className="content-body">
      <Container>
        <Row>
          <Col md={6} sm={6}>
            <div className="app-stat card bg-primary">
              <Card.Body className="p-4">
                <div className="media flex-wrap">
                  <span className="me-3">
                    <FaGem className="flaticon-381-calendar-1" />
                  </span>
                  <div className="media-body text-white text-end">
                    <p className="mb-1">Lifetime Earnings </p>
                    <h3 className="text-white">
                      <FaRupeeSign />
                      {totalAmount}
                    </h3>
                  </div>
                </div>
              </Card.Body>
            </div>
          </Col>
          <Col md={6} sm={6}>
            <div className="app-stat card bg-success">
              <Card.Body className="p-4">
                <div className="media flex-wrap">
                  <span className="me-3">
                    <FaGem className="flaticon-381-calendar-1" />
                  </span>
                  <div className="media-body text-white text-end">
                    <p className="mb-1">Latest Payout</p>
                    <h3 className="text-white">
                      <FaRupeeSign />
                      {payoutHistory[0] && payoutHistory[0].amount}
                    </h3>
                  </div>
                </div>
              </Card.Body>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="artists-table">
            <Card>
              <Card.Header>
                <h4 className="card-title">
                  <mark>Payout History</mark>
                </h4>
                {/* <Button
                  as={Link}
                  to="/request-payout"
                  className="theme-btn request_payout_btn"
                >
                  Request Payout
                </Button> */}

                <Dropdown className="float-end">
                  <Dropdown.Toggle variant="secondary">
                    Show {entriesPerPage} rows
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {[10, 50, 100].map((num) => (
                      <Dropdown.Item
                        key={num}
                        onClick={() => setEntriesPerPage(num)}
                      >
                        {num} rows
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Card.Header>
              <Card.Body>
                <div className="table-responsive">
                  <Table responsive="md" variant="dark">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Payout Period</th>
                        <th>Payout Date</th>
                        <th>Amount</th>
                        {/* <th>Status</th> */}
                        <th>Remarks</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {currentEntries.length > 0 ? (
                        currentEntries.map((payout, index) => (
                          <tr key={index}>
                            <td>
                              <strong>{payout.payoutId}</strong>
                            </td>
                            <td>
                              {new Date(
                                payout.payoutStartDate
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })}{" "}
                              -{" "}
                              {new Date(
                                payout.payoutEndDate
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })}
                            </td>
                            <td>
                              {new Date(payout.payoutDate).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                }
                              )}
                            </td>
                            <td>₹ {payout.amount}</td>
                            <td>{payout.remarks}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5">No payout history available.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <Pagination variant='dark' className="justify-content-center mt-3">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  <span className="mx-3">
                    Showing {indexOfFirstEntry + 1} to{" "}
                    {Math.min(indexOfLastEntry, payoutHistory.length)} of{" "}
                    {payoutHistory.length}
                  </span>
                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Payouts;
