import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import {
  FaCalendarAlt,
  FaGem,
  FaHeart,
  FaRupeeSign,
  FaUserMd,
} from "react-icons/fa";
import { GiCash, GiMusicalNotes, GiUpgrade } from "react-icons/gi";
import "./DashboardStats.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const DashboardStats = () => {
  const [payoutHistory, setPayoutHistory] = useState([]);
  const [artistId, setArtistId] = useState("");

  useEffect(() => {
    // Get the artistId from localStorage
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (profile && profile.artistId) {
      setArtistId(profile.artistId);
    }

    // Fetch the payout history from the API
    const fetchPayoutHistory = async () => {
      try {
        const response = await axios.get(
          `https://fttunes.com/api/payouts/artist/${profile.artistId}`
        );
        console.log("payouts", response.data);
        setPayoutHistory(response.data);
      } catch (err) {
      } finally {
      }
    };

    if (profile && profile.artistId) {
      fetchPayoutHistory();
    }
  }, []);

  const totalAmount = payoutHistory.reduce(
    (acc, payout) => acc + payout.amount,
    0
  );

  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        // Fetch artistId from localStorage
        const artistProfile = JSON.parse(localStorage.getItem("profile"));
        const artistId = artistProfile?.artistId;

        if (!artistId) {
          return;
        }

        // Call the API to fetch albums by the artist
        const response = await axios.get(
          `https://fttunes.com/api/artists/${artistId}/albums`
        );
        setAlbums(response.data.data); // Assuming API returns albums in `data.data`
      } catch (err) {}
    };

    fetchAlbums();
  }, []);
  const navigate = useNavigate();

  const handleplan = () => {
    navigate("/artist/plans");
  };

  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem("profile"))
  );
  useEffect(() => {
    setProfile(JSON.parse(localStorage.getItem("profile")));
    console.log("profile", profile);
    const fetchprofile = async () => {
      try {
        const response = await axios.get(
          `https://fttunes.com/api/artists/${profile.artistId}`
        );
        localStorage.setItem("profile", JSON.stringify(response.data));
        // console.log("response", response);
        // setProfile(response.data); // Initialize form data with artist profile from API
      } catch (error) {
        console.error("Error fetching artist data:", error);
      }
    };
    fetchprofile();
  }, []);

  return (
    <Row>
      <Col md={3} sm={6}>
        <div className="app-stat card bg-danger">
          <Card.Body className="p-4">
            <div className="media flex-wrap">
              <span className="me-3">
                <FaCalendarAlt className="flaticon-381-calendar-1" />
              </span>
              <div className="media-body text-white text-end">
                <p className="mb-1">Upcoming Releases</p>
                <h3 className="text-white">
                  {" "}
                  {albums.filter((album) => album.status !== "Released").length}
                </h3>
              </div>
            </div>
          </Card.Body>
        </div>
      </Col>

      <Col md={3} sm={6}>
        <div className="app-stat card bg-success">
          <Card.Body className="p-4">
            <div className="media flex-wrap">
              <span className="me-3">
                <FaGem className="flaticon-381-diamond" />
              </span>
              <div className="media-body text-white text-end">
                <p className="mb-1 text-nowrap">Latest Payout</p>
                <h3 className="text-white">
                  <FaRupeeSign />
                  {payoutHistory[0] ? payoutHistory[0].amount : 0}
                </h3>
              </div>
            </div>
          </Card.Body>
        </div>
      </Col>

      <Col md={3} sm={6}>
        <div className="app-stat card bg-info">
          <Card.Body className="p-4">
            <div className="media flex-wrap">
              <span className="me-3">
                <GiCash className="flaticon-381-heart" />
              </span>
              <div className="media-body text-white text-end">
                <p className="mb-1 text-nowrap">Lifetime earnings</p>
                <h3 className="text-white">
                  <FaRupeeSign />
                  {totalAmount}
                </h3>
              </div>
            </div>
          </Card.Body>
        </div>
      </Col>

      <Col md={3} sm={6}>
        <div className="app-stat card bg-primary">
          <Card.Body className="p-4">
            <div className="media flex-wrap">
              <span className="me-3">
                <GiMusicalNotes className="flaticon-381-user-7" />
              </span>
              <div className="media-body text-white text-end">
                <p className="mb-1">Total Releases</p>
                <h3 className="text-white">
                  {albums.filter((album) => album.status === "Released").length}
                </h3>
              </div>
            </div>
          </Card.Body>
        </div>
      </Col>

      {((profile.plan_purchased === "0" || profile.plan_purchased === null) && (profile.role !== "Record Label")) && (
        <Col md={12} sm={12}>
          <div
            className="app-stat card "
            style={{
              background: "#35353587",
              // boxShadow:
              //   "rgba(128, 0, 255, 0.25) 0px 54px 55px, rgba(128, 0, 255, 0.12) 0px -12px 30px, rgba(128, 0, 255, 0.12) 0px 4px 6px, rgba(128, 0, 255, 0.17) 0px 12px 13px, rgba(128, 0, 255, 0.09) 0px -3px 5px",
            }}
          >
            <Card.Body className="p-4">
              <div className="media flex-wrap" style={{ alignItems: "center" }}>
                <span className="me-3">
                  <GiUpgrade className="flaticon-381-diamond" />
                </span>
                <div className="media-body text-white text-end">
                  <p
                    className="mb-1 text-nowrap"
                    style={{ fontSize: "xx-large", fontWeight: "700" }}
                  >
                    {(profile.plan_purchased === "0" || profile.plan_purchased === null) 
                      ? "New Artist"
                      : profile.plan_purchased}
                  </p>
                  <Button
                    className="text-white"
                    style={{
                      background: "transparent",
                      padding: "5px 10px",
                      border: "1px solid #fff",
                      borderRadius: "25px",
                    }}
                    // onClick={() => handleplan()}
                    href="/artist/plans"
                    target="_blank"
                  >
                    {/* <FaRupeeSign />
                  {payoutHistory[0] && payoutHistory[0].amount} */}
                    Upgrade your account
                  </Button>
                </div>
              </div>
            </Card.Body>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default DashboardStats;
