import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, Table, Badge, Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import { FaCaretDown, FaEye, FaPencil } from "react-icons/fa6";

const AllTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem("profile"))
  );
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get(
          `https://fttunes.com/api/support/tickets/artist/${profile.artistId}`
        );
        setTickets(response.data.tickets);
      } catch (error) {
        console.error("Error fetching tickets by artist ID", error);
      }
    };
    fetchTickets();
  }, []);

  const handleRaiseTicket = () => {
    navigate("/artist/raise-ticket");
  };

  const handleOpenTicket = (ticket_id) => {
    navigate(`/artist/tickets/${ticket_id}`);
  };

  const editProfile = () => {
    navigate("/artist/profile");
  };

  console.log("profile", profile);
  console.log("tickets", tickets);

  return (
    <div className="content-body ticket-bg">
      <div className="p-4 text-light min-vh-100"  style={{background:"#0c0c0c"}}>
        <Row>
          {/* Left Side - Artist Details and Contacts */}
          <Col md={3}>
            <Card bg="secondary" text="light" className="mb-4">
              <Card.Header className="text-center">Artist Info</Card.Header>
              <Card.Body>
                <Card.Title>
                  <mark>
                    {profile.firstName} {profile.lastName}
                  </mark>
                </Card.Title>
                <Card.Text>
                  {profile.role}
                  <br />
                  {profile.description}
                  {profile.description && <br />}
                  {profile.address}
                  {profile.address && <br />}
                  {profile.city}
                  {profile.city && ", "}
                  {profile.state}
                  {profile.state && ", "}
                  {profile.pincode}
                  <br />
                  {profile.country}
                  {profile.country && <br />}
                  {profile.plan_purchased}
                  {profile.plan_purchased && <br />}
                  ID: {profile.artistId}
                </Card.Text>
                <Button
                  variant="success"
                  className="w-100 mt-3"
                  onClick={() => editProfile()}
                >
                  Update
                </Button>
              </Card.Body>
            </Card>

            <Card bg="secondary" text="light">
              <Card.Header className="text-center">Contacts</Card.Header>
              <Card.Body>
                <Card.Text>
                  Phone : {profile.phone}
                  <br />
                  Email : {profile.email}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={9}>
            <Row className="mb-4">
              <Col md={3}>
                <Card className="bg-secondary text-white">
                  <Card.Body>
                    <Card.Title>Total Tickets</Card.Title>
                    <Card.Text>{tickets.length}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="bg-success text-white">
                  <Card.Body>
                    <Card.Title>Resolved Tickets</Card.Title>
                    <Card.Text>
                      {
                        tickets.filter((ticket) => ticket.status === "Closed")
                          .length
                      }
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="bg-warning text-white">
                  <Card.Body>
                    <Card.Title>Pending Tickets</Card.Title>
                    <Card.Text>
                      {
                        tickets.filter(
                          (ticket) => ticket.status === "In Progress"
                        ).length
                      }
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="bg-danger text-white">
                  <Card.Body>
                    <Card.Title>Open Tickets</Card.Title>
                    <Card.Text>
                      {
                        tickets.filter((ticket) => ticket.status === "Open")
                          .length
                      }
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Card className="bg-secondary text-white">
              <Card.Header
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <p>Support Tickets </p>
                <Button onClick={() => navigate("/artist/raise-ticket")}>
                  Raise Ticket
                </Button>
              </Card.Header>
              <Card.Body>
                <Table bordered hover variant="dark">
                  <thead>
                    <tr>
                      <th>Ticket Id</th>
                      <th>Subject</th>
                      <th>Ticket Raised</th>
                      {/* <th>Last Reply</th> */}
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets.map((ticket, index) => (
                      <tr key={index}>
                        <td onClick={() => handleOpenTicket(ticket.ticket_id)}>
                          {ticket.ticket_id}
                        </td>
                        <td onClick={() => handleOpenTicket(ticket.ticket_id)}>
                          {ticket.subject}
                        </td>
                        <td onClick={() => handleOpenTicket(ticket.ticket_id)}>
                          {ticket.created_at}
                        </td>
                        {/* <td>{ticket.lastReply}</td> */}
                        <td onClick={() => handleOpenTicket(ticket.ticket_id)}>
                          <Badge
                            bg={
                              ticket.status === "Open" ? "success" : "secondary"
                            }
                          >
                            {ticket.status}
                          </Badge>
                        </td>
                        <td>
                          <div className="d-flex" style={{ gap: "10px" }}>
                            <Button
                              variant="primary"
                              size="sm"
                              style={{ padding: "0.625rem 1rem" }}
                              onClick={() => handleOpenTicket(ticket.ticket_id)}
                            >
                              <FaEye />
                            </Button>
                            <Button
                              variant="danger"
                              size="sm"
                              style={{ padding: "0.625rem 1rem" }}
                              // onClick={() => handleDeleteAlbum(album.id)}
                            >
                              More <FaCaretDown />
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AllTickets;
