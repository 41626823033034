import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LabelArtists = () => {
  const navigate = useNavigate();
  const [artistsUnderLabel, setArtistsUnderLabel] = useState([]);
  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem("profile"))
  );

  useEffect(() => {
    if (!profile || profile.role !== "Record Label") return;

    const fetchArtistsUnderLabel = async () => {
      try {
        const response = await axios.get("https://fttunes.com/api/artists");
        console.log("response for artistsUnderLabel", response.data);

        const labelArtists = response.data.filter(
          (artist) => artist.labelId === profile.artistId
        );

        setArtistsUnderLabel(labelArtists);
        console.log("artist under label", labelArtists);
      } catch (error) {
        console.error("Error fetching artists under label:", error);
      }
    };

    fetchArtistsUnderLabel();
  }, [profile]);
  return (
    <div style={{ marginBottom: "25px" }}>
      <Col xs={12} className="artists-table">
        <Card className="filter-tab m-0" style={{ padding: "15px" }}>
          <Card.Header
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              border: "none",
            }}
          >
            <Card.Title style={{ color: "white" }}>
              Artists Under the Label
            </Card.Title>
            <Button
              variant="warning"
              className="fw-bold"
              onClick={() => navigate("/artist/add-artist-under-label")}
            >
              + Add Artist
            </Button>
          </Card.Header>
          <Card.Body className="bs-0 p-0 bg-transparent">
            <Col md={12}>
              <Row>
                {artistsUnderLabel && artistsUnderLabel.length > 0 ? (
                  artistsUnderLabel.map((artist, index) => (
                    <Col md={4} key={index}>
                      <Card className="artist-card text-white shadow-sm mb-3" style={{background:"#000"}}>
                        <Card.Body>
                          <div className="d-flex align-items-center">
                            <img
                              src={
                                artist.profileImage
                                  ? `https://fttunes.com/api/${artist.profileImage}`
                                  : "/Images/cartoon.png"
                              }
                              alt={`${artist.firstName} ${artist.lastName}`}
                              width={80}
                              height={80}
                              className="artist-avatar me-3"
                            />
                            <div>
                              <Card.Title style={{fontSize:"unset"}}>
                                {artist.firstName} {artist.lastName}
                              </Card.Title>
                            </div>
                          </div>
                        </Card.Body>
                        <ListGroup variant="flush">
                          <ListGroup.Item className="text-white" style={{background:"#000"}}>
                            <strong>Email:</strong> {artist.email}
                          </ListGroup.Item>
                          <ListGroup.Item className="text-white" style={{background:"#000"}}>
                            <strong>Status:</strong>
                            <Badge
                              bg={
                                artist.status === "Approved"
                                  ? "success"
                                  : "secondary"
                              }
                              className="ms-2"
                            >
                              {artist.status}
                            </Badge>
                          </ListGroup.Item>
                        </ListGroup>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <p className="text-center text-muted">
                    No artists found under this label.
                  </p>
                )}
              </Row>
            </Col>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default LabelArtists;
