import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  Dropdown,
  Container,
  Image,
  Button,
  NavLink,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const Header = () => {
  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem("profile"))
  );
  useEffect(() => {
    setProfile(JSON.parse(localStorage.getItem("profile")));
    console.log("profile", profile);
  }, []);

  const location = useLocation(); // Get the current path
  // Map URLs to headings
  const headingMap = {
    "/artist/dashboard": "Artist Dashboard",
    "/artist/releases": " ",
    "/artist/releases/create": "Create New Release",
    "/artist/incomplete-releases": " ",
    "/artist/payouts": "My Payouts",
    "/artist/profile": "My Profile",
    "/artist/reports": "Reports",
  };

  // Handle dynamic routes with :id (e.g., /artist/releases/edit/:id)
  const getDynamicHeading = (path) => {
    if (/^\/artist\/releases\/edit\/[a-zA-Z0-9]+$/.test(path)) {
      return "Edit Release"; // Match /artist/releases/edit/:id
    }
    return headingMap[path] || "Artist Dashboard"; // Default to Artist Dashboard
  };

  // Get the heading based on the current path
  const currentHeading = getDynamicHeading(location.pathname);

  const [isAuthenticated, setIsAuthenticated] = useState(false); // New state to track authentication
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    const checkAuthStatus = () => {
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;

          if (decodedToken.exp > currentTime) {
            setIsAuthenticated(true); // Token is valid, user is authenticated
          } else {
            localStorage.removeItem("token");
            setIsAuthenticated(false);
            // navigate("/signin");
          }
        } catch (error) {
          localStorage.removeItem("token");
          setIsAuthenticated(false);
          // navigate("/signin");
        }
      }
    };

    checkAuthStatus();
  }, [token, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/signin");
  };

  const [showSubMenu, setShowSubMenu] = useState(false);
  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };

  return (
    <div className="header">
      <div className="header-content">
        <Navbar expand="md" className="navbar-expand" style={{ width: "100%" }}>
          <Container fluid className="d-flex justify-space-between">
            {/* Left Side - Dashboard Title */}
            <div className="header-left">
              {/* <div className="dashboard_bar">{currentHeading}</div> */}
            </div>

            {/* Right Side - Navigation Items */}
            <Nav
              className="navbar-nav header-right"
              style={{ gap: "30px", alignItems: "center" }}
            >
              {/* Theme Mode Toggle */}
              <Nav.Item className="nav-item dropdown notification_dropdown">
                <Nav.Link
                  as={Link}
                  className="nav-link bell dz-theme-mode "
                  to="/artist/releases"
                >
                  Releases
                  {/* <FaSun />
                  <FaMoon /> */}
                </Nav.Link>
              </Nav.Item>

              {/* Notification Icon */}
              <Nav.Item className="nav-item dropdown notification_dropdown">
                <Nav.Link
                  as={Link}
                  className="nav-link bell bell-link"
                  to="/artist/reports"
                >
                  {/* <i className="flaticon-381-pad"></i> */}
                  Reports
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="nav-item dropdown notification_dropdown">
                <Nav.Link
                  as={Link}
                  className="nav-link bell bell-link"
                  to="/artist/payouts"
                >
                  {/* <i className="flaticon-381-pad"></i> */}
                  Payouts
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="nav-item dropdown notification_dropdown">
                <Nav.Link
                  as={Link}
                  className="nav-link bell bell-link"
                  to="/artist/incomplete-releases"
                >
                  {/* <i className="flaticon-381-pad"></i> */}
                  Drafts
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="nav-item dropdown notification_dropdown">
                <Nav.Link
                  as={Link}
                  className="nav-link bell bell-link"
                  to="/artist/tickets"
                >
                  {/* <i className="flaticon-381-pad"></i> */}
                  Support
                </Nav.Link>
              </Nav.Item>

              <Nav.Item className="nav-item dropdown notification_dropdown">
                <Link className="add_release_btn" to="/artist/releases/create">
                  {/* <i className="flaticon-381-pad"></i> */}
                  Add New Release
                </Link>
              </Nav.Item>

              {/* Fullscreen Toggle */}
              {/* <Nav.Item className="nav-item dropdown notification_dropdown">
                <Nav.Link className="nav-link dz-fullscreen" href="#">
                  <svg
                    id="icon-full"
                    viewBox="0 0 24 24"
                    width="26"
                    height="26"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
                  </svg>
                  <svg
                    id="icon-minimize"
                    width="26"
                    height="26"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-minimize"
                  >
                    <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"></path>
                  </svg>
                </Nav.Link>
              </Nav.Item> */}

              {/* Notifications Dropdown */}
              {/* <Dropdown
                as={Nav.Item}
                className="nav-item dropdown notification_dropdown"
              >
                <Dropdown.Toggle as={Nav.Link} className="nav-link ai-icon">
                  <i className="flaticon-381-ring"></i>
                  <div className="pulse-css"></div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                  <div
                    id="DZ_W_Notification1"
                    className="widget-media dz-scroll p-3"
                    style={{ height: "380px" }}
                  >
                    <ul className="timeline">
                      <li>
                        <div className="timeline-panel">
                          <div className="media me-2">
                            <Image
                              alt="image"
                              width="50"
                              src="http://mediqu-asp-dotnet-core-mvc-bootstrap-admin-dashboard-template.imageslelo.com/mediqu/Images/avatar/1.jpg"
                            />
                          </div>
                          <div className="media-body">
                            <h6 className="mb-1">Dr sultads Send you Photo</h6>
                            <small className="d-block">
                              29 July 2021 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
\
                    </ul>
                  </div>
                  <Dropdown.Item href="#">
                    See all notifications <i className="ti-arrow-right"></i>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}

              {/* User Profile Dropdown */}
              <Dropdown
                as={Nav.Item}
                className="nav-item dropdown header-profile"
              >
                <Dropdown.Toggle
                  as={Nav.Link}
                  className="nav-link profilenavlink"
                >
                  {/* {profile && (
                    <div className="header-info">
                      <span>
                        {profile.firstName} {profile.lastName}
                      </span>
                      <small>{profile.role.toUpperCase()}</small>
                    </div>
                  )} */}
                  {profile && (
                    <Image
                      src={
                        profile.profileImage
                          ? `https://fttunes.com/api/${profile.profileImage}`
                          : "/Images/cartoon.png"
                      }
                      width="20"
                      alt=""
                    />
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                  <Dropdown.Item
                    as={Link}
                    to="/artist/profile"
                    className="dropdown-item ai-icon"
                  >
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <span className="ms-2">Profile</span>
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    as={Link}
                    to="/artist/dashboard"
                    className="dropdown-item ai-icon"
                  >
                    <svg
                      id="icon-inbox"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-success"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </svg>
                    <span className="ms-2">Inbox</span>
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    // as={Link}
                    // to="/"
                    onClick={handleLogout}
                    className="dropdown-item ai-icon"
                  >
                    <svg
                      id="icon-logout"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-danger"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                      <polyline points="16 17 21 12 16 7"></polyline>
                      <line x1="21" y1="12" x2="9" y2="12"></line>
                    </svg>
                    <span className="ms-2">Logout</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Container>
        </Navbar>
      </div>
      {/* Hamburger Menu */}
      <Button
        className="nav-control p-0 menu_navbar d-none"
        variant="link"
        style={{ float: "right", marginRight: "10px", height: "100%" }}
        onClick={toggleSubMenu}
      >
        <div className="hamburger">
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </Button>

      {showSubMenu && (
        <div className="sidebar-container">
          <Nav className="flex-column" style={{ padding: "20px" }}>
            {/* <NavLink as={Link} to="/artist/dashboard" className="sidebar-link">
              Artist Dashboard
            </NavLink> */}
            <NavLink as={Link} to="/artist/releases" className="sidebar-link">
              Releases
            </NavLink>
            {/* <NavLink
              as={Link}
              to="/artist/releases/create"
              className="sidebar-link"
            >
              Create New Release
            </NavLink> */}
            <NavLink as={Link} to="/artist/reports" className="sidebar-link">
              Reports
            </NavLink>
            <NavLink as={Link} to="/artist/payouts" className="sidebar-link">
              My Payouts
            </NavLink>
            <NavLink
              as={Link}
              to="/artist/incomplete-releases"
              className="sidebar-link"
            >
              Drafts
            </NavLink>
            <NavLink
              as={Link}
              to="/artist/releases/create"
              className="sidebar-link add_release_btn"
            >
              Add New Release
            </NavLink>
            <NavLink as={Link} to="/artist/profile" className="sidebar-link">
              My Profile
            </NavLink>
            <NavLink as={Link} className="sidebar-link" onClick={handleLogout}>
              Logout
            </NavLink>
          </Nav>
        </div>
      )}
    </div>
  );
};

export default Header;
